import { Component } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';
import { CoolLocalStorage } from '@angular-cool/storage';

import * as moment from "moment";

import {
  ColDef,
  ColGroupDef,
  INumberFilterParams,
  ITextFilterParams,
  SizeColumnsToFitGridStrategy,
} from "ag-grid-community";

import {
  AgBarSeriesOptions,
  AgCategoryAxisOptions,
  AgChartCaptionOptions,
  AgChartLegendOptions,
  AgChartOptions,
  AgChartSubtitleOptions,
  AgLineSeriesOptions,
  AgNumberAxisOptions,
} from "ag-charts-community";

export class chartDates {
  constructor(public start: string, public end: string) { }
}

import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { IMyOptions } from 'ng-uikit-pro-standard';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { MetricsServicesService } from 'src/app/modules/metrics/services/metrics-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SizeColumnsToFitProvidedWidthStrategy } from 'ag-grid-community';
import { SizeColumnsToContentStrategy } from 'ag-grid-community';
import { UntypedFormControl } from '@angular/forms';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-metrics-readquest',
  templateUrl: './metrics-readquest.component.html',
  styleUrls: ['./metrics-readquest.component.scss'],
  providers: [TitleCasePipe]
})
export class MetricsReadquestComponent {

  //persistent
  private token: string;
  public teamID: number;
  public clientCode: string;
  public targetType: string = "targetType";
  public title: string = "RQ User Analytics";
  public users: any;
  private targetURL: string;

  //icons
  public faTimes = faTimes;

  //data
  private queryParams = {
    is_teacher: false,
    page_number: '1',
    page_size: '25',
    start: '',
    end: '',
    tmz: 'UTC'
  };

  //visibility
  public showDropdown: boolean = true;
  public gridLoading: boolean = true;
  public errorRetrievingMsg: string = "";

  //calendar config
  public model = new chartDates("", "");
  public startDateLabel: string = "Start Date";
  public endDateLable: string = "End Date";
  public date = new Date();
  public disableDatesObj = { year: 0, month: 0, day: 0 };
  public disabledUntil = { year: 0, month: 0, day: 0 };
  public myDatePickerOptions: IMyOptions = {
    alignSelectorRight: true,
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableSince: this.disableDatesObj,
  };
  public endDatePickerOptions: IMyOptions = {
    closeAfterSelect: true,
    dateFormat: "mmm d, yyyy",
    useDateObject: true,
    minYear: 2016,
    // to do: fucntion to return an object that gives the current day, take into account the 31, 30 and Feb.
    // disableSince: {year: moment(this.date).year(), month: moment().month() + 1, day: 16,},
    disableUntil: this.disabledUntil,
    disableSince: this.disableDatesObj,
  };

  //grid config
  public columnDefs: (ColDef | ColGroupDef)[] = [];
  public autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = {
      type: "fitCellContents",
      skipHeader: false
    };
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 220
  };
  public rowData!: any[];
  public skillRowData!: any[];
  public durtionChrtData!: any[];
  public themeClass: string =
    "ag-theme-quartz-dark";

  //charts
  public durationChartOptions: AgChartOptions;

  //options
  dropdownOptions = [{
    "label": "Student",
    "value": "student"
  },
  {
    "label": "Teacher",
    "value": "teacher"
  },
  ]
  public userTypeSelector: UntypedFormControl = new UntypedFormControl("student");

  //date/time
  public tz: any;
  public tz_iana: any;

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService,
    private _metricsServicesService: MetricsServicesService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private TitleCase: TitleCasePipe
  ) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveTeamID();
    this.retrieveClientCode();

    let getTimezones = this._metricsServicesService.resolveTimeZone();
    this.tz_iana = getTimezones.tz_iana
    this.tz = getTimezones.tz;
    this.queryParams.tmz = this.tz_iana;

    this.buildColumnDefs();

    // Listen for route changes
    combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).subscribe(([params, queryParams]) => {

      this.targetURL = `rqu/team/${this.teamID}/user-data`;

      const retrievedParams = this._metricsServicesService.retrieveQueryParams(queryParams);

      this.queryParams.is_teacher = retrievedParams.is_teacher
      this.userTypeSelector.setValue(retrievedParams.userType);

      this.queryParams.start = retrievedParams.start;
      this.queryParams.end = retrievedParams.end;
      this.model = new chartDates(
        moment(retrievedParams.start).format("MMM D, YYYY"),
        moment(retrievedParams.end).format("MMM D, YYYY")
      );

      if (retrievedParams.legacyState) this.targetURL = `team/${this.teamID}/readquest/user-data`;

      this.retrieveData();
    });
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private retrieveTeamID() {
    this.teamID = JSON.parse(
      this.coolLocalStorage.getItem("admin_panel_team_id")
    );
  }

  private buildColumnDefs() {

    let columns = [
      {
        headerName: 'First', field: 'first_name', type: 'text', filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: 'Last', field: 'last_name', type: 'text', filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: 'Username', field: 'username', type: 'text', filter: "agTextColumnFilter", filterParams: {
          buttons: ["clear"],
        } as ITextFilterParams
      },
      {
        headerName: 'Skills Attempted', field: 'unique_skill_attempts_count', type: 'number', filter: 'agNumberColumnFilter', filterParams: {
          buttons: ["clear"],
        } as INumberFilterParams
      },
      {
        headerName: 'Skills Complete', field: 'skills_complete', type: 'number', filter: 'agNumberColumnFilter'
      },
      {
        headerName: 'Total Usage (hh:mm:ss)', field: 'total_duration', type: 'time'
      },
      {
        headerName: 'Last Usage', field: 'latest_end_time', type: 'date'
      }
    ]

    this.columnDefs = this._metricsServicesService.createColumnDefinitions(columns);
  }

  private async retrieveData() {

    let users = await this.retrieveUsers().catch((error) => {
      this.errorRetrievingMsg = "Error retrieving users. Please try again later.";
      this.gridLoading = false;
    });

    this.users = users;

    let gridData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.processGridData(gridData);
    this.buildChartOptions();

    this.gridLoading = false;
  }

  private retrieveGridData() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let data = {
      queryParams: this.queryParams
    }

    return this._metricsServicesService.restfulForetellAPIRequest(this.targetURL, "get", data, getOptions).toPromise();

  }

  private retrieveUsers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let retrieveUsers = this._xrPlatformRestService.retrieveEntityCollection(
      "team",
      "users",
      this.teamID,
      options,
      true
    );

    return retrieveUsers.toPromise();
  }

  private processGridData(gridData) {
    let userData = gridData.user_data.map((user) => {

      //find user from this.users via user.external_user_id
      let foundUser = this.users.find((u) => u.id === user.external_user_id);

      if (foundUser) {
        user.first_name = foundUser.first_name;
        user.last_name = foundUser.last_name;
        user.username = foundUser.username;
      }

      return user;

    })

    this.rowData = userData;

    let durtionChrtData = gridData.user_data.map((user) => {

      let thisUser = {
        name: `${user.first_name} ${user.last_name}\n(${user.username})`,
        duration: user.total_duration,
        skills: user.unique_skill_attempts_count,
        most_recently: user.latest_end_time
      }

      console.log("thisUser", thisUser);

      return thisUser;
    });

    this.durtionChrtData = durtionChrtData;
  }

  private buildChartOptions() {

    const self = this;

    this.durationChartOptions = {
      // Chart Title
      title: { text: "Skill Attempts and Duration Per User" } as AgChartCaptionOptions,
      // Chart Subtitle
      subtitle: { text: `${this.TitleCase.transform(this.userTypeSelector.value)} Data` } as AgChartSubtitleOptions,
      data: this.durtionChrtData,
      series: [
        {
          type: "bar",
          xKey: "name",
          yKey: "skills",
          yName: "Skills Attempted",
          fill: "#A08E6A",
          tooltip: {
            renderer: function (params) {

              let skills = params.datum.skills;

              return `
            <div class="ag-chart-tooltip-title" style="background-color: ${params.color}">
                Skills Attempted
            </div>
            <div class="ag-chart-tooltip-content">
                ${parseInt(skills).toLocaleString()}
            </div>`;
            },
          }
        } as AgBarSeriesOptions,
        {
          type: "line",
          xKey: "name",
          yKey: "duration",
          yName: "Total Usage (hh:mm:ss)",
          stroke: "#4b2d1b",
          tooltip: {
            renderer: function (params) {

              let durationFormatted = self._metricsServicesService.convertDurationToTime(params.datum.duration);

              return `
            <div class="ag-chart-tooltip-title" style="background-color: ${params.color}">
                Total Usage (hh:mm:ss)
            </div>
            <div class="ag-chart-tooltip-content">
                ${durationFormatted}
            </div>`;
            },
          },
        } as AgLineSeriesOptions,
      ],
      axes: [
        // Display category (xKey) as the bottom axis
        {
          type: "category",
          position: "bottom",
        } as AgCategoryAxisOptions,
        // Use left axis for 'iceCreamSales' series
        {
          type: "number",
          position: "left",
          keys: ["skills"],
          title: {
            enabled: true,
            text: "Skill Attempts",
          },
          // Format the label applied to this axis
          label: {
            formatter: (params) => {
              return parseInt(params.value).toLocaleString();
            },
          },
        } as AgNumberAxisOptions,
        // Use right axis for 'avgTemp' series
        {
          type: "number",
          position: "right",
          keys: ["duration"],
          title: {
            enabled: true,
            text: "Amount of Time",
          },
          // Format the label applied to this axis (append ' °C')
          label: {
            formatter: (params) => {
              //convert seconds to a string in the form hh hours mm minutes, rounded to the closest minute
              let durationFormatted = self._metricsServicesService.convertDurationToTime(params.value);

              return `${durationFormatted}`;
            }
          },
          // Legend: Matches visual elements to their corresponding series or data categories.
          legend: {
            position: "right",
          } as AgChartLegendOptions,
        } as AgNumberAxisOptions,
      ],
    }
  }

  updateSubtitle(newSubtitle: string): void {
    this.durationChartOptions.subtitle.text = newSubtitle;
  }

  public onCellClicked(event) {

    this.router.navigate(['client', this.clientCode, 'analytics', 'readquest', 'user', event.data['user_id']], {
      queryParams: this.queryParams
    });

  }

  public async setupDateChart() {
    this.gridLoading = true;
    let start = this.model.start;
    let end = this.model.end;

    if (start === "" || end === "") return false;

    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(end).format("YYYY-MM-DD");
    let userType = this.userTypeSelector.value;
    this.updateSubtitle(`${this.TitleCase.transform(userType)} Data`);

    this.queryParams.start = startDate;
    this.queryParams.end = endDate;
    this.queryParams.is_teacher = userType === "teacher" ? true : false;

    // Prepare the query parameters string
    const queryParams = `start=${encodeURIComponent(startDate)}&end=${encodeURIComponent(endDate)}&is_teacher=${encodeURIComponent(this.queryParams.is_teacher)}&tmz=${encodeURIComponent(this.tz_iana)}`;

    // Update the URL without navigating
    const path = this.location.path();
    const pathWithoutParams = path.split('?')[0];
    this.location.replaceState(`${pathWithoutParams}?${queryParams}`);

    let gridData = await this.retrieveGridData().catch((err) => {
      this._notificationService.errorNotification(err.error.message);
    });

    this.processGridData(gridData);
    this.gridLoading = false;
  }
}
