import { CoolLocalStorage } from "@angular-cool/storage";
import { DOCUMENT, TitleCasePipe } from "@angular/common";
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MDBModalRef, MDBModalService } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { NotificationsService } from "src/app/services/utilities/notifications.service";

import {
  faTimes,
  faCirclePlus,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import { EventSettingsManageMediaComponent } from "src/app/modules/event-management/modals/event-settings-manage-media/event-settings-manage-media.component";

interface Body {
  label?: string;
  description?: string;
  organization?: string;
  archived?: boolean;
  asset_id?: number;
}

@Component({
  selector: 'app-authoring-skills-management',
  templateUrl: './authoring-skills-management.component.html',
  styleUrls: ['./authoring-skills-management.component.scss'],
  providers: [TitleCasePipe],
  encapsulation: ViewEncapsulation.None
})
export class AuthoringSkillsManagementComponent implements OnInit {
  //incoming
  public action: string;
  public targetSkill: any;
  public skills: any;

  //persistent
  public token: string;
  public labels: any;
  public skillMedia: any;

  //form values
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
      close: "Close",
      main: "Add",
      reset: "Add Another Skill",
      retry: "Retry",
    };

  private preSelected: {
    label: string;
    organization: string;
    description: string;
    asset_id: number;
  };

  public skillForm: UntypedFormGroup;
  public formLoading: boolean = true;
  public formState: string = "active";

  //outbound
  private outgoing: Subject<any> = new Subject();

  //user feeback
  public notification: any;

  //tokens
  public faTimes = faTimes;
  public faCirclePlus = faCirclePlus;
  public faFilePdf = faFilePdf;

  //modal related
  private modalOptions = {
    backdrop: "static",
    keyboard: false,
    focus: true,
    show: false,
    ignoreBackdropClick: true,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };
  public selectMediaFrame: MDBModalRef;

  constructor(
    private _xrPlatformRestService: XrPlatformRestService,
    public skillFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _notificationService: NotificationsService,
    private TitleCase: TitleCasePipe,
    private modalService: MDBModalService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.retrieveToken();
    this.retrieveLabels();
    this.buildPreselected();
    this.makeValidatingForm();
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveLabels() {
    this.labels = JSON.parse(this.coolLocalStorage.getItem("the_panel_labels"));
  }

  private buildPreselected() {
    if (!this.targetSkill) {
      this.preSelected = {
        label: "",
        organization: "",
        description: "",
        asset_id: 0,
      };

      this.skillMedia = null;
    } else {
      this.preSelected = {
        label: this.targetSkill.label,
        organization: this.targetSkill.organization,
        description: this.targetSkill.description,
        asset_id: this.targetSkill.asset_id,
      };

      this.skillMedia = this.targetSkill.asset !== null ? this.targetSkill.asset : null;
    }
  }

  private makeValidatingForm() {
    this.skillForm = new UntypedFormGroup({
      label: new UntypedFormControl(
        this.preSelected.label,
        Validators.required
      ),
      organization: new UntypedFormControl(this.preSelected.organization),
      description: new UntypedFormControl(this.preSelected.description),
      asset_id: new UntypedFormControl(this.preSelected.asset_id),
    });

    this.formLoading = false;
  }

  public async manageSkill() {
    this.formState = "processing";

    let actionAdj = this.action.replace(/e$/, "");
    this.notification = this._notificationService.savingNotification(
      `${this.TitleCase.transform(actionAdj)}ing skill...`
    );

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let method = this.action === "add" ? "post" : "put";
    let target = this.action === "add" ? "skill" : `skill/${this.targetSkill.id}`;

    const body: Body = this.action === 'archive'
      ? { archived: true }
      : {
        label: this.skillForm.value.label,
        organization: this.skillForm.value.organization,
        description: this.skillForm.value.description,
        asset_id: this.skillForm.value.asset_id,
      };

    let addSkill = await this._xrPlatformRestService.restfulAPIQuery(
      `/authoring/${target}`,
      method,
      body,
      options
    ).toPromise().catch((error) => {
      this.formState = "error";
      this._notificationService.errorNotification(
        "There was an error while adding the skill. Please try again or contact support"
      );
    });

    if (addSkill) {

      let outbound = {
        action: this.action,
        skill: addSkill.skill,
      };

      this.outgoing.next(outbound);
      this.formState = "active";
      this._notificationService.clearNotification(this.notification);
      this.skillFrame.hide();
    }

  }

  public openSelectMediaModalForProp() {
    let mainEventDisplay = this.document.getElementsByClassName(
      "manage-experiences-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    this.modalOptions.data = {
      teamID: 1,
      type: "pdf",
      targetAsset: this.targetSkill.asset !== null ? this.targetSkill.asset : null,
      fromLocation: "manageSkill",
      experience: null,
      labels: this.labels,
      parentModalClass: "manage-experiences-container",
      typeLabel: "PDF",
      buttonLabel: "Update and Return to Skill"
    };

    this.modalOptions.class =
      this.modalOptions.class + " modal-full-height modal-right";
    this.modalOptions.containerClass = "event-media-container";
    this.selectMediaFrame = this.modalService.show(
      EventSettingsManageMediaComponent,
      this.modalOptions
    );

    this.selectMediaFrame.content.outgoing.subscribe((changedData) => {
      if (changedData.media !== undefined && changedData.media.length) {

        this.skillForm.controls.asset_id.setValue(changedData.media[0].id);
        this.skillMedia = changedData.media[0];

      } else if (changedData.media === undefined || !changedData.media.length) {

        this.skillForm.controls.asset_id.setValue(null);
        this.skillMedia = null;
      }
    });
  }
}
