import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  Component,
  OnInit,
  ViewChildren,
  Inject,
  ViewChild,
} from "@angular/core";
import { pairwise, startWith } from "rxjs/operators";

import {
  faCirclePlus,
  faImages,
  faPlusSquare,
  faMinus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import {
  CollapseComponent,
  MDBModalRef,
  MDBModalService,
  PopoverDirective,
} from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { EventSettingsManageMediaComponent } from "src/app/modules/event-management/modals/event-settings-manage-media/event-settings-manage-media.component";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-hubs-within-a-zone",
  templateUrl: "./hubs-within-a-zone.component.html",
  styleUrls: ["./hubs-within-a-zone.component.scss"],
})
export class HubsWithinAZoneComponent implements OnInit {
  @ViewChildren(CollapseComponent) collapses!: CollapseComponent[];

  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  //fa icons
  public faCirclePlus = faCirclePlus;
  public faImages = faImages;
  public faMinus = faMinus;
  public faPlusSquare = faPlusSquare;
  public faMinusSquare = faMinus;
  public faTimes = faTimes;

  //incoming data
  public zoneNumber: number;
  public zones: any;
  public zonesSelectBase: { value: number; label: string }[];
  public currentExperienceID: number;
  public experiences: any;
  public labels: any;
  public teamID: number;
  public action: string;

  // placeholders
  public placeHolderImg: string = "assets/img/placeholder-image.jpeg";

  //edit tracking
  public isClean: boolean = true;

  //zone selection arrays
  public zonesSelect: any[] = [];

  //form vars
  public zoneHubsForm: any;
  public formLoading: boolean = true;
  public numberOfZones: number = 0;

  //additional important data
  public currentExperience: any = null;

  //outgoing
  private outgoing: Subject<any> = new Subject();

  //modals
  public selectMediaFrame: MDBModalRef;
  private modalOptions = {
    backdrop: "static",
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    public zoneHubsFrame: MDBModalRef,
    private modalService: MDBModalService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    if (this.currentExperienceID !== null) {
      this.currentExperience = this.getExperienceByID(
        this.currentExperienceID
      )[0].experience;

      this.numberOfZones = this.getNumberOfHubs(this.currentExperience);
    }

    this.makeForm();
  }

  private getNumberOfHubs(experience) {
    let num_of_hubs_attr = experience.experience_attributes.filter(
      (attr) => attr.experience_attribute.name === "max_number_of_zones"
    );

    let number_of_hubs = 0;

    if (num_of_hubs_attr.length) {
      number_of_hubs = parseInt(num_of_hubs_attr[0].default_value);
    }

    return number_of_hubs;
  }

  private makeForm() {
    this.zoneHubsForm = new UntypedFormGroup({
      zones: new UntypedFormArray([]),
    });

    this.setupZones();

    if (this.action === "add") this.isClean = false;

    this.formLoading = false;
  }

  private setupZones() {
    //loop through zones and add a form control for each using addZones()
    //experience_id = zone.experience_event_rel.expeirence_id
    //label = zone.label
    if (this.zones !== null && this.zones.length > 0) {
      this.zones.forEach((zone, index) => {
        let experience_id =
          zone.experience_id !== undefined && zone.experience_id !== ""
            ? zone.experience_id
            : null;

        let currentExperience = this.getExperienceByID(experience_id);

        this.addZones(
          index,
          currentExperience.length
            ? currentExperience[0].experience
            : undefined,
          zone
        );
      });
    } else {
      //loop through number of zones and add a form control for each using addZones()
      for (let i = 0; i < this.numberOfZones; i++) {
        this.addZones(i);
      }
    }
  }

  //start zones
  // convenience getters for easy access to form fields
  get zoneHubsFormControls() {
    return this.zoneHubsForm.controls;
  }

  get zonesFormArraySub() {
    return this.zoneHubsFormControls.zones as UntypedFormArray;
  }

  get zonesFieldsGroups() {
    return this.zonesFormArraySub.controls as UntypedFormGroup[];
  }

  //get a zone_extra form array by index
  getZoneExtras(index) {
    return this.zonesFieldsGroups[index].controls
      .zone_extras as UntypedFormArray;
  }

  //get zone extras fields groups by index
  getZoneExtrasFieldsGroups(index) {
    return this.getZoneExtras(index).controls as UntypedFormGroup[];
  }

  public addZones(index?, experience?, zone?) {
    if (index === undefined) index = 0;

    if (this.zonesFormArraySub === undefined) return;

    let newZoneForm = new UntypedFormGroup({
      zone_experience_id: new UntypedFormControl(
        experience === undefined ? "" : experience.id
      ),
      zone_label: new UntypedFormControl(zone === undefined ? "" : zone.label),
      zone_object: new UntypedFormControl(zone === undefined ? null : zone),
      has_extras: new UntypedFormControl(
        this.determineExtras(experience === undefined ? null : experience)
      ),
      zone_extras: new UntypedFormArray([]),
      has_hub: new UntypedFormControl(
        experience === undefined
          ? false
          : experience.experience_type.name === "hub"
            ? true
            : false
      ),
    });

    //track changes to zone experience_id
    let start_with = experience === undefined ? "" : experience.id;

    newZoneForm
      .get("zone_experience_id")
      .valueChanges.pipe(startWith(start_with), pairwise())
      .subscribe(([prev, next]) => {
        if (prev !== next) this.isClean = false;
      });

    //track changes to zone label
    start_with = zone === undefined ? "" : zone.label;

    newZoneForm
      .get("zone_label")
      .valueChanges.pipe(startWith(start_with), pairwise())
      .subscribe(([prev, next]) => {
        if (prev !== next) this.isClean = false;
      });

    this.zonesFormArraySub.push(newZoneForm);

    //add zone extras
    this.addZoneExtras(experience, zone, index);

    this.zonesSelect[index] = JSON.parse(JSON.stringify(this.zonesSelectBase));

    //listen for changes to zone experience_id and update zone selects
    this.zonesFormArraySub.controls[index].valueChanges
      .pipe(
        startWith({
          zone_experience_id: experience === undefined ? "" : experience.id,
        }),
        pairwise()
      )
      .subscribe(([prev, next]: [any, any]) => {
        //this is a starting experience change
        if (
          prev !== null &&
          prev.zone_experience_id === next.zone_experience_id
        )
          return;

        //reset form protection
        if (
          next.zone_experience_id !== undefined &&
          next.zone_experience_id === null
        )
          return;

        let thisExperience = this.getExperienceByID(next.zone_experience_id);

        let has_extras = this.determineExtras(thisExperience[0].experience);

        this.zonesFieldsGroups[index].controls["has_extras"].setValue(
          has_extras
        );

        if (has_extras) {
          this.collapses.forEach(
            (collapse: CollapseComponent, collapse_index) => {
              if (collapse_index === index) {
                collapse.show();
              }
            }
          );

          //reset extras
          for (let i = this.getZoneExtras(index).length - 1; i >= 0; i--) {
            this.getZoneExtras(index).removeAt(i);
          }

          this.addZoneExtras(
            thisExperience[0].experience,
            this.zonesFieldsGroups[index].controls["zone_object"].value,
            index
          );
        } else {
          this.collapses.forEach(
            (collapse: CollapseComponent, collapse_index) => {
              if (collapse_index === index) {
                collapse.hide();
              }
            }
          );

          //reset extras
          for (let i = this.getZoneExtras(index).length - 1; i >= 0; i--) {
            this.getZoneExtras(index).removeAt(i);
          }
        }
      });
  }

  //start zone extras
  /**
   * @description this function creates a formarray of zone extras
   *
   * With the incoming experience object, we can iterate through the experience_attributes and determine if any are editable or not
   *
   * Editable experiences are then turned into a formgroup and added to the zoneExtrasFormArray
   *
   * @param experience
   */
  public addZoneExtras(experience, zone, index) {
    if (experience === undefined) return;
    if (experience.experience_attributes === undefined) return;

    let zoneExtrasFormArray = this.getZoneExtras(index);

    experience.experience_attributes.forEach((attribute) => {
      let valueHandler = this.determineExtrasValue(
        zone,
        attribute.experience_attribute.name,
        attribute.experience_attribute.type
      );

      if (attribute.experience_attribute.editable) {
        let zoneExtraForm = new UntypedFormGroup({
          attribute_id: new UntypedFormControl(
            attribute.experience_attribute.id
          ),
          attribute_value: new UntypedFormControl(valueHandler.value),
          attribute_label: new UntypedFormControl(
            attribute.experience_attribute.label
          ),
          attribute_name: new UntypedFormControl(
            attribute.experience_attribute.name
          ),
          attribute_full_data: new UntypedFormControl(valueHandler.fullData),
          attribute_type: new UntypedFormControl(
            attribute.experience_attribute.type
          ),
          attribute_subtype: new UntypedFormControl(
            attribute.experience_attribute.subtype
          ),
        });

        //track changes to zone extras attribute_value
        let start_with = valueHandler.value;

        zoneExtraForm
          .get("attribute_value")
          .valueChanges.pipe(startWith(start_with), pairwise())
          .subscribe(([prev, next]) => {
            if (prev !== next) this.isClean = false;
          });

        //track changes to zone extras attribute_label
        start_with = attribute.experience_attribute.label;

        zoneExtraForm
          .get("attribute_label")
          .valueChanges.pipe(startWith(start_with), pairwise())
          .subscribe(([prev, next]) => {
            if (prev !== next) this.isClean = false;
          });

        //track changes to zone extras attribute_name
        start_with = attribute.experience_attribute.name;

        zoneExtraForm
          .get("attribute_name")
          .valueChanges.pipe(startWith(start_with), pairwise())
          .subscribe(([prev, next]) => {
            if (prev !== next) this.isClean = false;
          });

        //track changes to zone extras attribute_full_data
        start_with = valueHandler.fullData;

        zoneExtraForm
          .get("attribute_full_data")
          .valueChanges.pipe(startWith(start_with), pairwise())
          .subscribe(([prev, next]) => {
            if (prev !== next) this.isClean = false;
          });

        //track changes to zone extras attribute_type

        start_with = attribute.experience_attribute.type;

        zoneExtraForm
          .get("attribute_type")
          .valueChanges.pipe(startWith(start_with), pairwise())
          .subscribe(([prev, next]) => {
            if (prev !== next) this.isClean = false;
          });

        //track changes to zone extras attribute_subtype
        start_with = attribute.experience_attribute.subtype;

        zoneExtraForm
          .get("attribute_subtype")
          .valueChanges.pipe(startWith(start_with), pairwise())
          .subscribe(([prev, next]) => {
            if (prev !== next) this.isClean = false;
          });

        //track changes to zone extras attribute_id
        start_with = attribute.experience_attribute.id;

        zoneExtraForm
          .get("attribute_id")
          .valueChanges.pipe(startWith(start_with), pairwise())
          .subscribe(([prev, next]) => {
            if (prev !== next) this.isClean = false;
          });

        zoneExtrasFormArray.push(zoneExtraForm);
      }
    });
  }

  private determineExtras(incomingExperience) {
    if (incomingExperience === null) return false;

    //if experience_attributes are undefined or null, return false
    if (
      incomingExperience.experience_attributes === undefined ||
      incomingExperience.experience_attributes === null
    )
      return false;

    //if this a hub, return true
    if (incomingExperience.experience_type.name === "hub") return true;

    let extras = false;
    //loop through experience_attributes, and look at the property of experience_attribute.editable
    //if any attribute has experience_attribute.editable as true, return true
    incomingExperience.experience_attributes.forEach((attribute) => {
      if (attribute.experience_attribute.editable) extras = true;
    });

    return extras;
  }

  /**
   * @description determine zone extra value from the incoming zone object; the target value is zone.experience_event_rel.experience_attributes[attribute_key]
   *
   * @param zone: zone object
   * @param attribute_key: string
   */
  private determineExtrasValue(zone, attribute_key, attribute_type) {
    let outboundValue = {
      value: null,
      fullData: null,
    };

    if (zone === null) return outboundValue;

    if (this.action === "add") {
      if (!zone.zone_extras.length) return outboundValue;

      zone.zone_extras.forEach((zone_extra) => {
        if (zone_extra.attribute_name === attribute_key) {
          outboundValue.value = zone_extra.attribute_value;
          outboundValue.fullData = zone_extra.attribute_full_data;
        }
      });

      return outboundValue;
    }

    if (zone.experience_event_rel.experience_attributes === null)
      return outboundValue;

    //make sure experience_attribute with attribute key exists
    if (
      zone.experience_event_rel.experience_attributes[attribute_key] ===
      undefined
    )
      return outboundValue;

    switch (attribute_type) {
      case "asset_single":
        if (
          zone.experience_event_rel.experience_attributes[attribute_key] ===
          null
        )
          break;

        outboundValue.value =
          zone.experience_event_rel.experience_attributes[attribute_key].id;
        outboundValue.fullData =
          zone.experience_event_rel.experience_attributes[attribute_key];
        break;
    }

    return outboundValue;
  }

  public checkThumbnail(fullData) {
    if (fullData === null) return null;

    //if fullData does not have a thumbnail_asset_url property or that property is null, return the placeholder image
    if (
      fullData.thumbnail_asset_url === undefined ||
      fullData.thumbnail_asset_url === null
    )
      return null;

    //if fullData has a thumbnail_asset_url property, return that
    return fullData.thumbnail_asset_url;
  }

  public openSelectMediaModal(extras_index, zone_index, subtype, asset) {
    let mainEventDisplay =
      this.document.getElementsByClassName("zone-hub-container");

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("restore-primary", "fade");
      mainEventDisplay.item(0).classList.add("secondary");
    }

    this.modalOptions.data = {
      teamID: this.teamID,
      type: subtype,
      targetAsset: asset,
      fromLocation: "zoneMedia",
      parentModalClass: "zone-hub-container",
    };

    this.modalOptions.class =
      this.modalOptions.class + " modal-full-height modal-right";
    this.selectMediaFrame = this.modalService.show(
      EventSettingsManageMediaComponent,
      this.modalOptions
    );

    this.selectMediaFrame.content.outgoing.subscribe((result: any) => {
      if (result.media !== undefined && result.media.length) {
        let media = result.media[0];

        let zoneExtrasFieldsGroups = this.getZoneExtrasFieldsGroups(zone_index);
        let zoneExtra = zoneExtrasFieldsGroups[extras_index];

        zoneExtra.controls["attribute_value"].setValue(media.id);
        zoneExtra.controls["attribute_full_data"].setValue(media);
      } else if (result.media !== undefined && !result.media.length) {
        let zoneExtrasFieldsGroups = this.getZoneExtrasFieldsGroups(zone_index);
        let zoneExtra = zoneExtrasFieldsGroups[extras_index];

        //reset the media
        zoneExtra.controls["attribute_value"].setValue(null);
        zoneExtra.controls["attribute_full_data"].setValue(null);
      }
    });
  }

  public restoreExperienceSelect(
    currentValue,
    targetKey,
    currentSelects,
    selects,
    baseSelects,
    currentFormArray
  ) {
    let restoredValue = this.experiencesSelectGetValue(
      currentValue,
      baseSelects
    );

    currentSelects.unshift(restoredValue[0]);

    currentFormArray.controls.forEach((control, control_index) => {
      let selectedArray = JSON.parse(JSON.stringify(currentSelects));

      if (control.value[targetKey] === "") {
        selects[control_index] = selectedArray;
        return;
      }

      let currentExperience = this.experiencesSelectGetValue(
        control.value[targetKey],
        baseSelects
      );

      selectedArray.unshift(currentExperience[0]);
    });

    return {
      currentSelects: currentSelects,
      selects: selects,
    };
  }

  private removeExperienceFromSelects(
    experience_id,
    index,
    currentSelects,
    selects
  ) {
    let currentExperience = {
      value: null,
      label: null,
    };
    currentSelects.forEach((option, option_index) => {
      if (option.value === experience_id) {
        currentExperience = option;
      }
    });

    currentSelects = currentSelects.filter((option) => {
      return option.value !== experience_id;
    });

    selects = this.experiencesSelectSetupEach(
      index,
      experience_id,
      currentExperience,
      currentSelects,
      selects
    );

    return {
      currentSelects: currentSelects,
      selects: selects,
    };
  }

  private experiencesSelectSetupEach(
    index,
    experience_id,
    currentExperience,
    currentSelects,
    selects
  ): any {
    selects.forEach((select, select_index) => {
      if (select_index !== index) {
        select.forEach((option, option_index) => {
          if (option.value === experience_id) {
            selects[select_index].splice(option_index, 1);
          }
        });
      }

      let selectedArray = JSON.parse(JSON.stringify(currentSelects));
      selectedArray.unshift(currentExperience);

      selects[index] = selectedArray;
    });

    return selects;
  }

  private experiencesSelectGetValue(currentValue, baseSelects) {
    return baseSelects.filter((option) => {
      return option.value === currentValue;
    });
  }

  private getExperienceByID(experience_id) {
    return this.experiences.filter((experience) => {
      return experience.experience_id === experience_id;
    });
  }

  public updateZones() {
    this.outgoing.next({
      zones: this.zonesFormArraySub.value,
      isClean: this.isClean,
    });

    this.isClean = true;

    this.closeModal();
  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    if (!this.isClean && !overrule) return false;

    let mainEventDisplay = this.document.getElementsByClassName(
      "schedule-class-container"
    );

    if (mainEventDisplay.item(0) !== null) {
      mainEventDisplay.item(0).classList.remove("secondary");
      mainEventDisplay.item(0).classList.add("restore-primary");
    }

    this.zoneHubsFrame.hide();
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }
}
