<!-- Modal Content -->
<div class="modal-content-wrapper">
  <div class="modal-header text-center">
    <h4
      class="modal-title w-100 font-weight-bold"
      [ngClass]="{ removeTitle: action === 'delete' }"
    >
      <span>{{ action | titlecase }} Skill</span>
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="skillFrame.hide()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <div class="row">
        <ng-container *ngIf="action === 'archive'">
          <div class="col-12">
            <p class="font-16">Archive this skill?</p>
            <ul class="list-unstyled">
              <li>
                <span class="bold">Name:</span>
                <strong>{{ targetSkill.label }}</strong>
              </li>
              <li>
                <span class="bold">Organization:</span>
                <strong>{{ targetSkill.organization }}</strong>
              </li>
              <li>
                <span class="bold">Description:</span>
                <strong>{{ targetSkill.description }}</strong>
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-container *ngIf="action !== 'archive'">
          <form [formGroup]="skillForm">
            <div class="row p-0">
              <div class="col-12">
                <p>
                  <span class="action-msg-small">Required*</span>
                </p>
              </div>
            </div>
            <div class="md-form">
              <input
                type="text"
                id="skillLabel"
                class="form-control"
                formControlName="label"
                mdbInput
              />
              <label for="skillLabel">* Name</label>
              <mdb-error
                *ngIf="
                  skillForm.controls.label.invalid &&
                  (skillForm.controls.label.dirty ||
                    skillForm.controls.label.touched)
                "
              >
                Skill name is required
              </mdb-error>
            </div>
            <div class="md-form">
              <input
                type="text"
                id="skillOrganization"
                class="form-control"
                formControlName="organization"
                mdbInput
              />
              <label for="skillOrganization">Organization</label>
            </div>
            <div class="md-form">
              <input
                type="text"
                id="skillDescription"
                class="form-control"
                formControlName="description"
                mdbInput
              />
              <label for="skillDescription">Description</label>
            </div>
            <div class="md-form skill-pdf">
              <input
                hidden="true"
                type="text"
                id="skillAssetID"
                class="form-control"
                formControlName="asset_id"
                mdbInput
              />
              <button
                class="theme-modal-button btn"
                type="button"
                mdbBtnp
                mdbWavesEffect
                (click)="openSelectMediaModalForProp()"
              >
                <fa-icon [icon]="faCirclePlus"></fa-icon>
                <span
                  >{{ skillMedia ? "Update" : "Add" }} Skill PDF</span
                >
              </button>
              <span class="skill-media-thumb" *ngIf="skillMedia"
                ><fa-icon [icon]="faFilePdf"></fa-icon>
                {{ skillMedia.name }}</span
              >
              <span class="skill-media-thumb" *ngIf="!skillMedia"
                >No PDF selected</span
              >
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      data-dismiss="modal"
      (click)="manageSkill()"
      class="theme-modal-button btn"
    >
      {{ action | titlecase }} Skill
    </button>
    <button
      mdbBtn
      mdbWavesEffect
      type="button"
      size="md"
      color="link"
      data-dismiss="modal"
      (click)="skillFrame.hide()"
    >
      Cancel
    </button>
  </div>
</div>
