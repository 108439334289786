import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { throwError } from "rxjs";
import { ClientManagementService } from "src/app/services/utilities/client-management.service";
import { MetricsServicesService } from "../../../services/metrics-services.service";

@Component({
  selector: "app-gazing-breakdown",
  templateUrl: "./gazing-breakdown.component.html",
  styleUrls: ["./gazing-breakdown.component.scss"],
})
export class GazingBreakdownComponent implements OnInit {
  //incoming
  @Input("teamID") teamID: number;
  @Input("session_id") session_id: number;
  @Input("labels") labels: any;
  @Input("users") users: any;
  @Input("clientSettings") clientSettings: any;

  public loading: boolean = false;
  public error: boolean = false;
  public gazingData: any = [];
  public graphUsers: any = [];
  public rowSpan: number = 0;
  public colSpan: number = 0;

  constructor(
    private http: HttpClient,
    private _clientManagementService: ClientManagementService,
    private _metricsServicesService: MetricsServicesService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.getGazingData();
  }

  private getGazingData() {
    ;
    let tmz = Intl.DateTimeFormat().resolvedOptions().timeZone;

    //temporary workaround for strange user gazing breakdown issue for a particular session
    if (this.teamID === 19 && this.session_id === 20300) {
      setTimeout(() => {
        this.loading = false;
        this.error = true;
      }, 2000);
      return false;
    }

    let retrieveMetricsData =
      this._metricsServicesService.getUserGazingBreakdown(
        this.teamID,
        this.session_id,
        tmz
      );

    retrieveMetricsData.subscribe(
      (response: any) => {
        ;
        this.error = false;
        this.gazingData = response.Graph.Data;
        this.graphUsers = response.Graph.labelData.map((thisUser) => {

          let incomingUser = thisUser.username;

          //find label in users array as id
          let user = this.users.find((x) => x.id === parseInt(incomingUser));

          //as a fallback, check the username
          if (user === "undefined" && user.typeof === "string") {
            user = this.users.find((x) => x.username === incomingUser);
          }

          //if user is still not found, skip it
          if (user === "undefined" || user === null) {
            return;
          }

          console.log("user in gazing breakdown", user);

          let label = user.id;

          if (this.clientSettings.metricsUsername.unconstrained_default) label = user.username;

          return {
            id: incomingUser.id,
            username: label
          }

        });
        this.loading = false;
        this.colSpan = this.graphUsers.length + 2;
        this.rowSpan = this.graphUsers.length + 1;
      },
      (error) => {
        ;
        this.error = true;
      }
    );
  }

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return throwError(error);
  }
}
