import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, CanActivate, CanActivateChild, UrlTree } from "@angular/router";
import { Injectable } from "@angular/core";
import { CoolLocalStorage } from "@angular-cool/storage";
import { BehaviorSubject, from, Observable, Subject } from "rxjs";

import { XrPlatformRestService } from "./../rest/xr-platform/xr-platform-rest.service";

import * as moment from "moment";

import { environment } from "./../../../environments/environment";
import { NotificationsService } from "./notifications.service";
import { SharedDataService } from "../shared-data/shared-data.service";

@Injectable({
  providedIn: "root",
})
export class NeedAuthGuardService implements CanActivate, CanActivateChild {
  private token: string;
  public login: string = null;
  private user: any;
  private user_role_id: number = 0;
  private userClientCode: string;
  private userIDRetrieved: number;
  private canActivateCalled = false;

  //check login status
  private tokenRetrieved: string;
  private tokenStampRetrieved: string;
  public loginRetrieved: string;

  //events
  private navEndSource = new Subject<void>();
  public navEnd = this.navEndSource.asObservable();
  public;
  routeDataObserver = new BehaviorSubject({});

  constructor(
    private router: Router,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService,
    private _sharedDataService: SharedDataService
  ) { }

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    console.log("childRoute in need-auth canActivateChild", childRoute);
    console.log("state in need-auth canActivateChild", state);
    if (!this.canActivateCalled) {
      return this.canActivate(childRoute, state);
    }

    setTimeout(() => {
      this.routeDataObserver.next(childRoute.data);
    }, 250);

    // Optionally, reset the flag here if appropriate for your use case
    // this.canActivateCalled = false;
    // Or return a default value indicating authorization status
    return true; // or false, depending on your auth logic
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.canActivateCalled = true;
    const params = route["params"];

    console.log("params in need-auth", params);
    console.log("route in need-auth", route);
    console.log("state in need-auth", state);

    setTimeout(() => {
      this.routeDataObserver.next(route.data);
    }, 250);

    let extras = this.router.getCurrentNavigation().extras;

    console.log("extras in need-auth", extras);

    let clientCode = "1";
    let roles = [];

    if (route.data !== undefined && route.data["roles"] !== undefined) {
      roles = route.data["roles"] as Array<string>;
    }

    if (
      extras.state !== undefined &&
      extras.state.legacyRedirectStatus !== undefined &&
      extras.state.legacyRedirectStatus === "complete"
    ) {
      this.navEndSource.next();
      return true;
    }

    this.retrieveToken();
    this.retrieveClientCode();
    this.retrieveUser();

    let needTeamID = false;
    let legacyClientRouting = false;

    if (Object.keys(params).length > 0) {
      if (params.client_code !== undefined) {
        clientCode = params.client_code;

        var hexReg = /^([0-9a-z]{6}){1,2}$/i;

        //if the clientCode is an int and is less then 3 chars long, this might be a legacy URL with the clientID
        if (!hexReg.test(clientCode.toLowerCase()) && clientCode !== "login") {
          legacyClientRouting = await this.maybeRouteClient(
            clientCode,
            state.url
          );
        }

        //for super admins we need to set the client code dynamically,
        //when a super admin traverses from one client to another
        if (
          this.user !== null &&
          this.userClientCode !== clientCode &&
          this.user_role_id === 1
        ) {
          needTeamID = true;
        }
      }
    }

    console.log("legacyClientRouting", legacyClientRouting);

    if (legacyClientRouting) return true;

    //check status of local storage first
    let status = this.checkLocalStorage();

    console.log("status", status);

    if (status === "logged-out") {

      let previousLoginstate = this.coolLocalStorage.getItem(
        "admin_panel_user_logged_in"
      )

      console.log("previousLoginstate", previousLoginstate);

      this.initiateLogout(clientCode, previousLoginstate ? true : false, state);
    } else {

      //set a session flag
      let myTimestamp: number = moment().unix();
      sessionStorage.setItem("admin_panel_current_session", `${myTimestamp}`);

      if (needTeamID) {
        let response = await this.retrieveTeamIDByCode(clientCode);

        this.coolLocalStorage.setObject("admin_panel_team_id", response.id);
        this.coolLocalStorage.setItem("admin_panel_clientcode", clientCode);

        let getLabels = await this.retrieveLabels(response.id);

        this.coolLocalStorage.setObject("the_panel_labels", getLabels.labels);

        return this.completeAuthCheck(params, roles, clientCode);
      } else if (this.user === null && state.url.indexOf("login") > -1) {
        //in this case we're clearly dealing with a login page, so we're done for now
        this.navEndSource.next();
        return true;
      } else {
        //labels across clients
        //@todo better way to do this?
        let teamID = this.coolLocalStorage.getItem("admin_panel_team_id");

        let getLabels = await this.retrieveLabels(teamID);

        this.coolLocalStorage.setObject("the_panel_labels", getLabels.labels);

        return this.completeAuthCheck(params, roles, clientCode);
      }
    }
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");

    if (this.user !== null) this.user_role_id = this.user.role_type_id;
  }

  private retrieveClientCode() {
    this.userClientCode = this.coolLocalStorage.getItem(
      "admin_panel_clientcode"
    );
  }

  private retrieveTeamIDByCode(clientCode) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let getTeamID = this._xrPlatformRestService.retrieveEntityData(
      "team/by-code",
      clientCode,
      options
    );

    return getTeamID.toPromise();
  }

  public retrieveLabels(teamID) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    return this._xrPlatformRestService
      .restfulAPIQuery(
        "/team/" + teamID + "/labels-to-place",
        "get",
        {},
        getOptions
      )
      .toPromise();
  }

  private async maybeRouteClient(maybeTeamID, currentURL) {
    let tryForTeam = await this.retrieveTeam(maybeTeamID).catch((error) => {
      //if somebody enters an incorrect team ID, we'll just route them to the core login page (submitting empty params will do this)
      this.redirectToLogin(null, "", true);
      return Promise.resolve(false);
    });

    if (tryForTeam.code !== undefined)
      this.redirectToLogin(tryForTeam.code, {
        currentURL: currentURL,
        teamID: maybeTeamID,
        true: true,
      });
    return Promise.resolve(true);
  }

  private retrieveTeam(teamID) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const options = {
      headers: headers,
    };

    let getTeam = this._xrPlatformRestService.retrieveEntityData(
      "team",
      teamID + "/code",
      options,
      true
    );

    return getTeam.toPromise();
  }

  public redirectToLogin(clientCode?, currentState?, fromLegacy?) {
    let redirect = "/login";

    if (clientCode !== undefined && clientCode !== null) {
      if (
        currentState !== undefined &&
        currentState.currentURL !== "" &&
        this.user !== null
      ) {
        redirect = currentState.currentURL.replace(
          currentState.teamID,
          clientCode
        );
      } else if (
        currentState !== undefined &&
        currentState.currentURL === "" &&
        this.user !== null
      ) {
        redirect = "/client/" + clientCode;
      } else {
        redirect = "/client/" + clientCode + "/login";
      }
    }

    let extras: NavigationExtras = {};

    if (fromLegacy) extras = {
      state: {
        legacyRedirectStatus: "complete",
      },
    };

    let navigation = this.router.navigate([redirect], extras);

    navigation.then(
      (response) => {
        this.navEndSource.next();
        this._notificationService.clearAllNotifications();
      },
      (err) => {
        this.navEndSource.next();
        ;
      }
    );
  }

  private completeAuthCheck(params, roles, clientCode) {
    let redirect = "/login";
    let permission = false;

    if (this.token !== undefined && this.token !== null) {
      //if limiting by roles, we need to check this
      if (roles.length) {
        permission = false;
        roles.forEach((role) => {
          switch (role) {
            case "super-admin":
              if (this.user_role_id === 1) permission = true;
              break;
            case "admin":
              if (this.user_role_id === 2) permission = true;
              break;
            case "curator":
              if (this.user_role_id === 3) permission = true;
              break;
            case "participant":
              if (this.user_role_id === 4) permission = true;
              break;
            case "viewer":
              if (this.user_role_id === 6) permission = true;
              break;
            case "dataview":
              if (this.user_role_id === 8) permission = true;
              break;
          }
        });
      } else {
        //i.e. access for all roles
        permission = true;
      }
    }

    //super admins always have access
    if (this.user_role_id === 1) {
      permission = true;
    } else {
      //roles below super admin only have access to specific instances
      if (this.user !== null && this.userClientCode === clientCode) {
        //need to figure out how to do this logic the right way
      } else {
        permission = false;
      }
    }

    //if the token is undefined, it means the user's session has timed out and they should be sent back to the login
    if (this.token === null) {
      permission = false;
    }

    if (Object.keys(params).length > 0) {
      if (params.client_code !== undefined) {
        if (permission) {
          redirect = "/client/" + params.client_code;
        } else {
          redirect = "/client/" + params.client_code + "/login";
        }
      }
    }

    if (permission) {
      this.navEndSource.next();
      return true;
    }

    let navigation = this.router.navigateByUrl(
      this.router.createUrlTree([redirect])
    );

    navigation.then(
      (response) => {
        this.navEndSource.next();
      },
      (err) => {
        this.navEndSource.next();
        ;
      }
    );

    return false;
  }

  private tokenRetrieve() {
    if (environment.localstorage) {
      return this.coolLocalStorage.getItem("admin_panel_jwt");
    } else {
      return "";
    }
  }

  private loginRetreive() {
    if (environment.localstorage) {
      return this.coolLocalStorage.getItem("admin_panel_username");
    } else {
      return "";
    }
  }

  private userIDRetreive() {
    if (environment.localstorage) {
      return parseInt(this.coolLocalStorage.getObject("admin_panel_userinfo"));
    } else {
      return 0;
    }
  }

  private tokenStampRetrieve() {
    if (environment.localstorage) {
      return this.coolLocalStorage.getItem("admin_panel_login_time_stamp");
    } else {
      return "";
    }
  }

  private checkLocalStorage() {
    let status = "logged-in";
    let myTimestamp: number = moment().unix();

    this.tokenRetrieved = this.tokenRetrieve();
    this.tokenStampRetrieved = this.tokenStampRetrieve();
    this.userIDRetrieved = this.userIDRetreive();
    this.loginRetrieved = this.loginRetreive();

    console.log("environment.localstorage", environment.localstorage);
    console.log("this.tokenRetrieved", this.tokenRetrieved);

    if (environment.localstorage) {
      if (this.tokenRetrieved !== null && this.loginRetrieved !== null) {
        this.token = this.tokenRetrieved;
        this.login = this.loginRetrieved;
      }

      if (this.tokenStampRetrieved === null || this.tokenRetrieved === null) {
        //cleanup tokenstamp if it's sticking around
        if (this.tokenStampRetrieved !== null) {
          this.coolLocalStorage.removeItem("admin_panel_login_time_stamp");
        }
        status = "logged-out";
      } else {
        let timeCheck = myTimestamp - parseInt(this.tokenStampRetrieved);

        console.log("timeCheck in need-auth", timeCheck)

        //standard expiration is 1 hour
        let expiration = 3600;

        //if the user is an admin and the url is localhost, expiration is 6 hours
        if (
          this.user_role_id === 1 &&
          location.origin.indexOf("localhost") > -1
        )
          expiration = 3600 * 24;

        if (timeCheck > expiration) {
          status = "logged-out";
        }
      }
    }

    return status;
  }

  public async initiateLogout(clientCode, initiate, state, skipExtend = false) {

    console.log("state in initiateLogout", state);

    let data = {
      initiate: initiate,
      token: this.token,
      skipExtend: skipExtend,
      clientCode: clientCode,
      user: this.user,
      userClientCode: this.userClientCode,
    }

    console.log("data in initiateLogout", data);

    if (state.url.indexOf("login") > -1) {
      this.logoutActions(clientCode, this.user);
      return;
    }

    this._sharedDataService.triggerSessionTimeout(data);
  }

  // Method to reset the flag, call this method at the appropriate time
  resetCanActivateCalled() {
    this.canActivateCalled = false;
  }

  public async logoutActions(clientcode, user) {
    let rememberCode = this.coolLocalStorage.getObject(
      "admin_panel_rememberLogin"
    );

    this.coolLocalStorage.clear();

    if (rememberCode) {
      setTimeout(() => {
        this.coolLocalStorage.setItem(
          "admin_panel_stored_code",
          clientcode
        );

        this.coolLocalStorage.setItem(
          "admin_panel_stored_username",
          user.username
        );

        this.coolLocalStorage.setObject(
          "admin_panel_stored_rememberLogin",
          true
        );
      }, 1);
    }

    setTimeout(() => {
      this.coolLocalStorage.setItem("admin_panel_user_logged_in", "false");
    }, 1);

    let outgoingData = {
      type: "logout",
      status: "success",
    };

    //let the sidenav know the logout is "successful"
    this._sharedDataService.sendSharedData(outgoingData);

    return Promise.resolve({
      "status": "success",
      "message": "You have been logged out."
    });
  }

}
