<div class="interactive-analytics-card">
  <div
    class="d-flex justify-content-between align-items-center w-100 metrics-controls"
  >
    <div class="back-button">
      <a
        mdbBtn
        class="btn-flat p-0 m-0 back-button"
        mdbWavesEffect
        [routerLink]="backRoute"
        [queryParams]="queryParams"
        mdbTooltip="{{backToolTip}}"
        placement="bottom"
      >
        <mdb-icon fas icon="chevron-left"></mdb-icon>
        Back
      </a>
    </div>
    <div class="d-flex justify-content-end align-items-center w-100">
      <div class="md-form start-picker">
        <mdb-date-picker
          [inline]="true"
          name="start"
          [options]="myDatePickerOptions"
          placeholder="Select Start"
          [(ngModel)]="model.start"
          id="start-date"
          onkeydown="return false"
        ></mdb-date-picker>
        <!-- <label [class.active]="start" for="start">Start Date</label> -->
      </div>
      <div class="md-form end-picker">
        <mdb-date-picker
          [inline]="true"
          name="end"
          [options]="endDatePickerOptions"
          placeholder="Select End"
          [(ngModel)]="model.end"
          id="end-date"
          onkeydown="return false"
        ></mdb-date-picker>
      </div>
      <div class="md-form denomination">
        <mdb-select
          name="time-denomination"
          [options]="dropdownOptions"
          [formControl]="userTypeSelector"
          placeholder="Select User Type"
        >
        </mdb-select>
        <label for="time-denomination">Time Interval</label>
      </div>
      <button
        class="theme-modal-button btn update-btn"
        data-dismiss="modal"
        type="button"
        mdbBtn
        mdbWavesEffect
        (click)="setupDateChart()"
      >
        Update Analytics
      </button>
    </div>
  </div>
  <div class="readquest-analytics-highlevel analytics-card">
    <ng-container *ngIf="gridLoading">
      <div class="row">
        <div class="col-12">
          <!-- if content hasnt load -->
          <div
            class="loading-card d-flex align-content-center justify-content-center"
          >
            <div
              class="d-flex justify-content-center"
              *ngIf="errorRetrievingMsg === ''"
            >
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div
              class="d-flex justify-content-center"
              *ngIf="errorRetrievingMsg !== ''"
            >
              <div
                class="alert alert-danger"
                role="alert"
                [innerHTML]="errorRetrievingMsg"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!gridLoading && !skillData.length">
      <div class="row">
        <div class="col-12">
          <div
            class="loading-card d-flex align-content-center justify-content-center"
          >
            <div class="alert alert-info" role="alert">
              No data available for this query.
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!gridLoading && skillData.length">
      <!-- <ul class="list-no-style">
      <li><span class="semi-bold">Total Actions:</span> 0</li>
      <li><span class="semi-bold">Percentage Complete:</span> 0</li>
      <li><span class="semi-bold">Number of attempts:</span> {{ skillData.length }}</li>
      <li><span class="semi-bold">Total Time Spent:</span> {{ skill.duration }}</li>
    </ul> -->

      <div
        class="skill-card content-card"
        *ngFor="let skillAttempt of skillData; let i = index"
      >
        <h4>Attempt {{ skillData.length - i }}: {{ skill.skill_name }}</h4>
        <ul class="list-no-style">
          <li>
            {{ skillAttempt.start_time }} -
            {{
              skillAttempt.end_time !== null
                ? skillAttempt.end_time
                : "Time Out"
            }}
          </li>
          <li>Duration: {{ skillAttempt.duration }}</li>
          <li>As: {{ skillAttempt.is_teacher ? "teacher" : "student" }}</li>
        </ul>
        <div
          class="readquest-analytics-skill-attempts"
          [ngStyle]="{ height: skillAttempt.actions.length * 5 + 3 + 'rem' }"
        >
          <ag-grid-angular
            style="width: 100%; height: 100%"
            [columnDefs]="columnDefs"
            [autoSizeStrategy]="autoSizeStrategy"
            [defaultColDef]="defaultColDef"
            [autoGroupColumnDef]="autoGroupColumnDef"
            [rowData]="skillAttempt.actions"
            [class]="themeClass"
            [noRowsOverlayComponent]="CustomNoRowsOverlayComponent"
            [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
          ></ag-grid-angular>
        </div>
      </div>
    </ng-container>
  </div>
</div>
