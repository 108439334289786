import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersistentModule } from "../persistent/persistent.module";
import { SharedModulesModule } from "../shared-modules/shared-modules.module";
import { AuthoringSkillsComponent } from './authoring-skills/authoring-skills.component';
import { AuthoringSkillActionsComponent } from './authoring-skill-actions/authoring-skill-actions.component';
import { AuthoringSkillsManagementComponent } from './authoring-skills/modals/authoring-skills-management/authoring-skills-management.component';

import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CloneSkillComponent } from './authoring-skills/modals/clone-skill/clone-skill.component';
import { InputNumberModule } from 'primeng/inputnumber';


@NgModule({
  declarations: [
    AuthoringSkillsComponent,
    AuthoringSkillActionsComponent,
    AuthoringSkillsManagementComponent,
    CloneSkillComponent
  ],
  imports: [
    CommonModule,
    SharedModulesModule,
    PersistentModule,
    TableModule,
    DropdownModule,
    CheckboxModule,
    InputTextModule,
    InputNumberModule,
    MultiSelectModule,
    ConfirmDialogModule,
    OverlayPanelModule
  ]
})
export class AuthoringModule { }
