<div class="modal-content-wrapper" (click)="modalClick($event)">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <span>Add {{ labels.user.plural | titlecase }}</span>
    </h4>
    <button
      *ngIf="isClean"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button
      *ngIf="!isClean"
      type="button"
      class="close cancel-alert"
      #popOverTriggerTop="bs-mdbPopover"
      id="cancelWarningCloseX"
      triggers="click"
      mdbPopoverHeader=""
      [mdbPopover]="cancelWarning"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <div class="row info-row">
        <div class="content info-padded w-100">
          <div id="uploadCSV">
            <form>
              <div class="file-field md-form">
                <div mdbBtn class="theme-modal-button btn">
                  <span>Upload CSV</span>
                  <input
                    mdbFileSelect
                    type="file"
                    #fileImportInput
                    name="csvFileUpload"
                    label="Upload CSV"
                    id="csvFileUpload"
                    (change)="fileChangeListener($event)"
                    accept=".csv"
                    [disabled]="formLoading"
                  />
                </div>
                <div class="file-path-wrapper">
                  <input
                    class="file-path"
                    type="text"
                    placeholder="Your CSV File"
                    disabled="true"
                  />
                </div>
              </div>
            </form>
          </div>
          <p>
            Add users to the system; users can optionally be added to a selected
            group, or create a new group to house this set of users.
          </p>
          <div class="form-wrapper search-or-create">
            <div
              *ngIf="formLoading"
              class="loading-screen d-flex align-items-center h-100 justify-content-center"
            >
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <form
              *ngIf="!formLoading"
              [formGroup]="userGroupForm"
              autocomplete="off"
            >
              <div class="form-radio-buttons group-type">
                <p class="m-0">
                  <span class="semi-bold"
                    >Add all new {{ labels.user.plural }} to a
                    {{ labels.userGroup.singular }} (optional):</span
                  >
                </p>
                <div class="group-type top ml-3">
                  <div class="d-flex">
                    <div
                      class="form-check form-check-inline"
                      [ngClass]="{ selected: groupType === 'none' }"
                    >
                      <input
                        type="radio"
                        class="form-check-input"
                        id="groupTypeNone"
                        [value]="'none'"
                        formControlName="group_type"
                        name="group_type"
                      />
                      <label class="form-check-label" for="groupTypeNone"
                        >None</label
                      >
                    </div>
                    <div
                      class="form-check form-check-inline"
                      [ngClass]="{ selected: groupType === 'existing' }"
                    >
                      <input
                        type="radio"
                        class="form-check-input"
                        id="groupTypeExisting"
                        [value]="'existing'"
                        formControlName="group_type"
                        name="group_type"
                      />
                      <label class="form-check-label" for="groupTypeExisting"
                        >Existing Group</label
                      >
                    </div>
                    <div
                      class="form-check form-check-inline"
                      [ngClass]="{ selected: groupType === 'new' }"
                    >
                      <input
                        type="radio"
                        class="form-check-input"
                        id="groupTypeNew"
                        [value]="'new'"
                        formControlName="group_type"
                        name="group_type"
                      />
                      <label class="form-check-label" for="groupTypeNew"
                        >New Group</label
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="md-form search" *ngIf="groupType === 'existing'">
                <input
                  type="text"
                  id="groupAuto"
                  formControlName="userGroup"
                  [ngModel]="searchText | async"
                  (ngModelChange)="searchText.next($event)"
                  [mdbAutoCompleter]="auto"
                  class="completer-input form-control mdb-autocomplete"
                  mdbValidate
                  mdbInput
                />
                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                <mdb-auto-completer
                  [displayValue]="onDisplayValue"
                  #auto="mdbAutoCompleter"
                  textNoResults="No results found"
                  [clearButton]="true"
                >
                  <mdb-option
                    *ngFor="let option of results | async"
                    [value]="option"
                  >
                    {{ option.name }}
                  </mdb-option>
                </mdb-auto-completer>
                <label class="active-label active" for="groupAuto"
                  >Search {{ labels.userGroup.plural | titlecase }}</label
                >
                <mdb-error
                  *ngIf="
                    userGroup.invalid && (userGroup.dirty || userGroup.touched)
                  "
                >
                  Group required
                </mdb-error>
              </div>

              <div *ngIf="groupType === 'new'" class="new-group">
                <div class="md-form group-name">
                  <input
                    type="text"
                    id="groupingForm-group-name"
                    class="form-control"
                    formControlName="group_name"
                    mdbInput
                    mdbValidate
                  />
                  <label
                    class="active-label active"
                    for="groupingForm-group-name"
                    >{{ labels.userGroup.singular | titlecase }} Name</label
                  >
                  <mdb-error
                    *ngIf="
                      group_name.invalid &&
                      (group_name.dirty || group_name.touched)
                    "
                  >
                    Group name required
                  </mdb-error>
                </div>
                <div class="md-form group-description">
                  <input
                    type="text"
                    id="groupingForm-group-description"
                    class="form-control"
                    formControlName="group_description"
                    mdbInput
                    mdbValidate
                  />
                  <label
                    class="active-label active"
                    for="groupingForm-group-description"
                    >{{
                      labels.userGroup.singular | titlecase
                    }}
                    Description</label
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        *ngIf="formLoading"
        class="loading-screen d-flex align-items-center h-100 justify-content-center"
      >
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <form
        [formGroup]="manageSystemUsersForm"
        class="edit-form repeater-form"
        [ngClass]="{ 'with-persona': showPersona }"
        *ngIf="!formLoading"
      >
        <div
          class="repeater"
          *ngFor="let systemUserFields of systemUserFieldsGroups; let i = index"
        >
          <div
            class="d-flex flexed repeater-item"
            [formGroup]="systemUserFields"
          >
            <div class="btn-group list-btns-sm btn-icons">
              <button
                [disabled]="systemUserFieldsGroups[i].status === 'INVALID'"
                mdbBtn
                mdbWavesEffect
                class="btn-flat btn-icon add"
                (click)="addUserFields()"
              >
                <fa-icon [icon]="faPlusSquare"></fa-icon>
              </button>
              <button
                [ngClass]="{ hidden: i === 0 }"
                mdbBtn
                mdbWavesEffect
                class="btn-flat btn-icon remove"
                (click)="removeUserField(i)"
              >
                <fa-icon [icon]="faMinus"></fa-icon>
              </button>
            </div>
            <div class="md-form first-name">
              <!--first name-->
              <input
                type="text"
                id="first_name"
                class="form-control"
                formControlName="first_name"
                mdbInput
                mdbValidate
                autocomplete="disabled"
              />
              <label class="active-label" for="first_name">* First name</label>
              <mdb-error
                *ngIf="
                  systemUserFields.controls.first_name.invalid &&
                  (systemUserFields.controls.first_name.dirty ||
                    systemUserFields.controls.first_name.touched)
                "
              >
                Please add a first name
              </mdb-error>
              <!--end first name-->
            </div>
            <div class="md-form last-name">
              <!--last name-->
              <input
                type="text"
                id="last_name"
                class="form-control"
                formControlName="last_name"
                mdbInput
                mdbValidate
                autocomplete="disabled"
              />
              <label class="active-label" for="last_name">* Last name</label>
              <mdb-error
                *ngIf="
                  systemUserFields.controls.last_name.invalid &&
                  (systemUserFields.controls.last_name.dirty ||
                    systemUserFields.controls.last_name.touched)
                "
              >
                Please add a last name
              </mdb-error>
              <!--end last name-->
            </div>
            <div class="md-form username">
              <!--last name-->
              <input
                type="text"
                id="username"
                class="form-control"
                formControlName="username"
                mdbInput
                mdbValidate
                autocomplete="disabled"
              />
              <label class="active-label" for="username">* Username</label>
              <mdb-error
                *ngIf="
                  systemUserFields.controls.username.invalid &&
                  (systemUserFields.controls.username.dirty ||
                    systemUserFields.controls.username.touched)
                "
              >
                <ng-container
                  *ngIf="
                    !systemUserFields.controls.username.errors.username_exists
                  "
                  >Please add a username</ng-container
                >
                <ng-container
                  *ngIf="
                    systemUserFields.controls.username.errors.username_exists
                  "
                  >Username already exists</ng-container
                >
              </mdb-error>
              <!--end last name-->
            </div>
            <div class="md-form email">
              <!--email-->
              <input
                type="email"
                id="email"
                class="form-control"
                formControlName="email"
                mdbInput
                mdbValidate
                autocomplete="disabled"
              />
              <label class="active-label" for="email">*Email</label>
              <mdb-error
                *ngIf="
                  systemUserFields.controls.email.invalid &&
                  (systemUserFields.controls.email.dirty ||
                    systemUserFields.controls.email.touched)
                "
              >
                <ng-container
                  *ngIf="!systemUserFields.controls.email.errors.email_exists"
                  >Please add a valid email</ng-container
                >
                <ng-container
                  *ngIf="systemUserFields.controls.email.errors.email_exists"
                  >Email already exists</ng-container
                >
              </mdb-error>
              <!--end email-->
            </div>
            <!--sys capability-->
            <div class="md-form system-capability">
              <mdb-select-2
                class="custom-arrow"
                mdbValidate
                placeholder="Choose {{
                  labels.systemCapability.singular | titlecase
                }}"
                label="* {{ labels.systemCapability.singular | titlecase }}"
                formControlName="role_type_id"
              >
                <mdb-select-option
                  *ngFor="let option of adminCaps"
                  [value]="option.value"
                  >{{ option.label }}</mdb-select-option
                >
              </mdb-select-2>
              <mdb-error
                *ngIf="
                  systemUserFields.controls.role_type_id.invalid &&
                  (systemUserFields.controls.role_type_id.dirty ||
                    systemUserFields.controls.role_type_id.touched)
                "
              >
                Please select a {{ labels.systemCapability.singular }}
              </mdb-error>
            </div>
            <!--end sys capability-->
            <div class="md-form">
              <!--role-->
              <mdb-select-2
                class="custom-arrow"
                mdbValidate
                placeholder="Choose {{
                  labels.eventRoles.singular | titlecase
                }}"
                label="* {{ labels.eventRoles.singular | titlecase }}"
                formControlName="role"
              >
                <mdb-select-option
                  *ngFor="let option of expRoleSelect"
                  [value]="option.value"
                  >{{ option.label }}</mdb-select-option
                >
              </mdb-select-2>
              <mdb-error
                *ngIf="
                  systemUserFields.controls.role.invalid &&
                  (systemUserFields.controls.role.dirty ||
                    systemUserFields.controls.role.touched)
                "
              >
                Please select a {{ labels.eventRoles.singular }}
              </mdb-error>
              <!--end role-->
            </div>
            <div class="md-form" *ngIf="showPersona">
              <!--persona-->
              <mdb-select-2
                class="custom-arrow"
                placeholder="Choose {{
                  labels.eventPersona.singular | titlecase
                }}"
                label="* {{ labels.eventPersona.singular | titlecase }}"
                formControlName="persona_id"
              >
                <mdb-select-option
                  *ngFor="let option of expPersonaSelect"
                  [value]="option.value"
                  >{{ option.label }}</mdb-select-option
                >
              </mdb-select-2>
              <mdb-error
                *ngIf="
                  systemUserFields.controls.role.invalid &&
                  (systemUserFields.controls.role.dirty ||
                    systemUserFields.controls.role.touched)
                "
              >
                Please select a {{ labels.eventPersona.singular }}
              </mdb-error>
              <!--end persona-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal-footer">
    <span
      *ngIf="!formLoading"
      id="mainEventAction"
      class="button-wrapper"
      triggers="focus"
      mdbPopoverHeader=""
      [mdbPopover]="
        (!manageSystemUsersForm.valid || !userGroupForm.valid) &&
        formState !== 'processing'
          ? mainEventAction
          : null
      "
      (shown)="showingWarning()"
    >
      <button
        [disabled]="
          !manageSystemUsersForm.valid ||
          !userGroupForm.valid ||
          isClean ||
          formState === 'processing'
        "
        mdbBtn
        type="button"
        mdbWavesEffect
        class="theme-modal-button btn"
        mdbWavesEffect
        (click)="inviteUsers()"
      >
        {{ btnLabel.main }}
      </button>
    </span>
    <button
      *ngIf="isClean"
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="closeModal()"
    >
      Close
    </button>
    <button
      *ngIf="!isClean"
      mdbBtn
      mdbWavesEffect
      #popOverTrigger="bs-mdbPopover"
      id="cancelWarning"
      type="button"
      color="link"
      class="theme-modal-cancel-button btn cancel-alert"
      mdbPopoverHeader=""
      triggers="click"
      [mdbPopover]="cancelWarning"
    >
      Cancel
    </button>
  </div>
</div>

<ng-template #mainEventAction
  ><div class="custom-pop error-pop">
    <p>Please review issues above.</p>
  </div></ng-template
>

<ng-template #cancelWarning
  ><div class="custom-pop error-pop wider near-right">
    <p class="semi-bold">
      Warning! All updates on these
      {{ labels.user.plural }} will be lost.
    </p>
    <div class="button-wrapper">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-button btn warning-stay"
        data-dismiss="modal"
      >
        Back to add {{ labels.user.plural }}
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn warning-leave"
        data-dismiss="modal"
        (click)="manageSystemUsersFrame.hide()"
      >
        Continue Cancel
      </button>
    </div>
  </div></ng-template
>
