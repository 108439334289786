import { Component, OnInit, ViewEncapsulation, Renderer2, Inject } from "@angular/core";
import { MDBModalRef } from "ng-uikit-pro-standard";
import { Subject } from "rxjs";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: "app-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {

  //data back to parent
  private outgoing: Subject<any> = new Subject();

  constructor(
    public logoutFrame: MDBModalRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
  }

  ngOnViewInit() {
    this.renderer.addClass(this.document.body, 'modal-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'logout-modal-open');
  }

  public extendSession() {

    let outgoingData = {
      status: "extend",
    };

    //let menu parent component know logout is "sucessful"
    this.outgoing.next(outgoingData);
    this.logoutFrame.hide();
  }

  public logout() {

    let outGoingData = {
      status: "logout",
    };

    this.outgoing.next(outGoingData);
    this.logoutFrame.hide();
  }
}
