import { DropzoneCustomModule } from './modules/ngx-dropzone-custom/ngx-dropzone-custom.module';
import { BrowserModule } from "@angular/platform-browser"
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { environment } from '../environments/environment';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HttpcancelService } from "./services/rest/xr-platform/httpcancel.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ManageHttpInterceptor } from "./services/rest/xr-platform/managehttp.interceptor";
// import { ManageHttpNoReturnInterceptor } from "./services/rest/xr-platform/managehttp.noreturn.interceptor";
import { GlobalparamsService } from "./services/rest/xr-platform/globalparams.service";

import { AuthenticationModule } from "./modules/authentication/authentication.module";
import { PersistentModule } from "./modules/persistent/persistent.module";
import { PagesModule } from "./modules/pages/pages.module";
import { UserManagementModule } from "./modules/user-management/user-management.module";
import { ExperienceManagementModule } from "./modules/experience-management/experience-management.module";
import { MetricsModule } from "./modules/metrics/metrics.module";
import { SuperAdminModule } from "./modules/super-admin/super-admin.module";
import { AudioPlayerModule } from "./modules/audio-player/audio-player.module";

import { ClientManagementService } from "./services/utilities/client-management.service";
import { XrPlatformRestService } from "./services/rest/xr-platform/xr-platform-rest.service";
import { MediaManagementModule } from "./modules/media-management/media-management.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import "hammerjs";
import { UniversalModule } from "./modules/universal/universal.module";
import { EventManagementModule } from "./modules/event-management/event-management.module";
import { CoolStorageModule } from "@angular-cool/storage";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ReusableModule } from "./modules/reusable/reusable.module";
import { WebComponentsModule } from "./modules/web-components/web-components.module";
import { AuthoringModule } from './modules/authoring/authoring.module';
import { DevOnlyModule } from './modules/dev-only/dev-only.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    AuthenticationModule,
    PersistentModule,
    PagesModule,
    UserManagementModule,
    ExperienceManagementModule,
    MetricsModule,
    MediaManagementModule,
    SuperAdminModule,
    BrowserAnimationsModule,
    UniversalModule,
    EventManagementModule,
    CoolStorageModule.forRoot(),
    FontAwesomeModule,
    ReusableModule,
    WebComponentsModule,
    AudioPlayerModule,
    DropzoneCustomModule,
    AuthoringModule,
    // ...(environment.includeSpecialComponent ? [DevOnlyModule] : []),
  ],
  providers: [
    XrPlatformRestService,
    ClientManagementService,
    HttpcancelService,
    CoolStorageModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ManageHttpNoReturnInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalparamsService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
